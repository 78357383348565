import React from "react"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { Eversports } from "../components/Eversports/Eversports"
import { graphql } from "gatsby"

interface PriceCardsProps {
  data: {
    cms: {
      page: {
        title: string
      }
    }
  }
}

const PriceCards: React.FC<PriceCardsProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <div className="flex flex-col items-center sm:w-11/12 lg:w-full">
        <PageTitle>{data.cms.page.title}</PageTitle>
        <Eversports
          title="coupons from eversports"
          widgetCode="t4ez9i?list=shop"
          height={1000}
          className="mx-16 w-full bg-white px-8 py-4 lg:w-10/12"
        />
      </div>
    </Layout>
  )
}

export default PriceCards

export const query = graphql`
  {
    cms {
      page(id: "/kurskarten", idType: URI) {
        title
      }
    }
  }
`
