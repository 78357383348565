import React from "react"
import { Heading } from "./Heading"

interface PageTitleProps {
  marginBottom?: string
  fontSize?: string
  lineWidth?: string
  styleForLegalPages?: boolean
  agbTag?: boolean
}
export const PageTitle: React.FC<PageTitleProps> = ({
  children,
  marginBottom = "mb-18",
  fontSize = "text-3xl",
  lineWidth = "w-104",
  styleForLegalPages = false,
  agbTag = false,
}) => {
  const divForLegalPages = (style: boolean, agb: boolean) => {
    if (style && !agb) {
      return "flex flex-col items-center mt-20"
    }
    if (style && agb) {
      return "flex flex-col items-center mt-20 text-2xl sm:text-3xl"
    }
  }
  return (
    <>
      <Heading
        className={`${divForLegalPages(styleForLegalPages, agbTag)} ${
          agbTag ? "" : fontSize
        } tracking-widest`}
      >
        {children}
      </Heading>
      <div
        className={`flex flex-col items-center border-b-2 border-lineColor ${lineWidth} ${marginBottom}
        }`}
        style={{ borderStyle: "groove" }}
      />
    </>
  )
}
