import React from "react"

interface HeadingProps {
  className?: string
}

export const Heading: React.FC<HeadingProps> = ({ className, children }) => {
  return (
    <h2
      className={`flex flex-col ${className} 
       mb-7 text-headings`}
    >
      {children}
    </h2>
  )
}
